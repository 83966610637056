<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="대상자 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-4">
                <c-text
                  :disabled="true"
                  label="건강검진 구분"
                  name="checkTypeName"
                  v-model="popupParam.checkTypeName"
                >
                </c-text>
              </div>
              <div class="col-4">
                <c-datepicker
                  :disabled="true"
                  label="건강검진일"
                  name="checkupDate"
                  v-model="popupParam.checkupDate"
                />
              </div>
            </template>
          </c-card>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="판정결과 목록"
            tableId="table"
            :columns="grid1.columns"
            :gridHeight="grid1.height"
            :data="grid1.data"
            :columnSetting="false"
            :filtering="false"
            :hideBottom="true"
            :usePaging="false"
          >
            <!-- 버튼 영역 -->
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table2"
            title="검진항목 목록"
            tableId="table2"
            :columns="grid2.columns"
            :data="grid2.data"
            :gridHeight="grid2.height"
            :columnSetting="false"
            :filtering="false"
            :hideBottom="true"
            :usePaging="false"
          >
            <!-- 버튼 영역 -->
          </c-table>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="table2"
            title="검진항목 목록"
            tableId="table2"
            :columns="grid2.columns"
            :data="grid2.data2"
            :gridHeight="grid2.height"
            :columnSetting="false"
            :filtering="false"
            :hideBottom="true"
            :usePaging="false"
          >
          </c-table>
        </div> -->
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'check-up-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupResultId: '',
        checkTypeName: '',
        checkupDate: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      editable: false,
      // 질환
      grid1: {
        columns: [
          
        ],
        data: [],
        height: '260px',
      },
      // 검진항목
      grid2: {
        columns: [
          {
            name: 'examineName1',
            field: 'examineName1',
            label: '검진항목',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'examineResult1',
            field: 'examineResult1',
            label: '검진결과',
            align: 'center',
            setHeader: true,
            style: 'width: 130px',
            sortable: false,
          },
          {
            name: 'examineName2',
            field: 'examineName2',
            label: '검진항목',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'examineResult2',
            field: 'examineResult2',
            label: '검진결과',
            align: 'center',
            setHeader: true,
            style: 'width: 130px',
            sortable: false,
          },
        ],
        data: [],
        data2: [],
        height: '600px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.grid1.height);
      if (height < 400) {
        height = 500;
      }
      this.grid2.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.hea.checkup.result.get.url;
      this.setHeader();
      this.getDetail();
    },
    setHeader() {
      if (this.popupParam.checkupTypeCd === 'CUTC000001') {
        this.grid1.columns = [
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width: 30px',
            sortable: true,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: 'LBLREMARK',
          //   align: 'left',
          //   style: 'width: 120px',
          //   sortable: true,
          // },
        ]
      } else if (this.popupParam.checkupTypeCd === 'CUTC000002') { 
        this.grid1.columns = [
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width: 30px',
            sortable: true,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'hazardNames',
            field: 'hazardNames',
            label: '관련유해인자',
            align: 'left',
            style: 'width: 400px',
            sortable: true,
          },
          {
            name: 'specialMeasures',
            field: 'specialMeasures',
            label: '특수조치',
            align: 'center',
            style: 'width: 50px',
            sortable: true,
          },
          {
            name: 'specialSuitability',
            field: 'specialSuitability',
            label: '특수업무적합성',
            align: 'center',
            style: 'width: 70px',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width: 120px',
            sortable: true,
          },
        ]
      }
    },
    getDetail() {
      if (this.popupParam.heaCheckupResultId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.heaCheckupResultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data.diseaseList && _result.data.diseaseList.length > 0) {
            this.grid1.data = _result.data.diseaseList;
          }
          if (_result.data.examineList && _result.data.examineList.length > 0) {
            this.grid2.data = _result.data.examineList;
          }
        },);
      } 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>